<template>
    <main>
        <h3 class="text-h3">TictacJob Application</h3>
        <div class="custom-card">
            <h4 class="text-h4">Bienvenue {{ currentUser.username }}</h4>
            <button class="btn-start">Commencer</button>
        </div>
    </main>
</template>
  
  <style>
    main {
        margin: 1rem 0;
    }
    
    h3 {
        color: #974ec3;
    }
  
    .custom-card {
        margin: 2rem 0;
        padding: 2rem;
        background-color: #f2e2fc;
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .btn-start {
      border: none;
      background-color: #974ec3;
      color: white;
      padding: 1rem 2rem;
      margin-top: 2rem;
      border-radius: 25rem;
      width: fit-content;
    }
</style>
  
<script>
  import { defineComponent } from 'vue';
  import { useUserStore } from '@/stores/user';
  
  export default defineComponent({
    name: 'MainPage',
  
    setup() {
      const userStore = useUserStore();
      const { currentUser } = userStore;

      return {
        currentUser,
      };
    },
  });
  </script>  