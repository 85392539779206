import { defineStore } from 'pinia';
import axios from 'axios';
import { env } from '../../env';

const BASE_API = `${env.apiURL}/workingtime`;

export const useWorkingTimeStore = defineStore('workingTime', {
  state: () => ({
    workingTimeList: [],
    currentWorkingTime: {
      id: "",
      entrance_datetime: new Date(),
      exit_datetime: new Date(),
      user_id: ""
    },
  }),

  actions: {
    getHeaders() {
      const token = localStorage.getItem('token');
      return {
        Authorization: `Bearer ${token}`,
      };
    },

    formatDateToString(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois commence à 0, donc on ajoute 1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
    
      // Retourne la date sous le format YYYY-MM-DDTHH:mm:ss
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },

    // Récupération de la liste des horaires de travail
    async fetchWorkingTimeList(userID, startTime, endTime) {
      const startTimeToString = this.formatDateToString(startTime);
      const endTimeToString = this.formatDateToString(endTime);
      try {
        const response = await axios.get(`${BASE_API}/${userID}?start=${startTimeToString}&end=${endTimeToString}`, { headers: this.getHeaders() });
        this.workingTimeList = response.data;
      } catch (error) {
        throw new Error(error.response.data?.message);
      }
    },

    // Récupération d'un horaire spécifique
    async fetchCurrentWorkingTime(workingTimeId, userID) {
      try {
        const response = await axios.get(`${BASE_API}/${userID}/${workingTimeId}`, { headers: this.getHeaders() });
        this.currentWorkingTime = response.data;
      } catch (error) {
        this.error = "Erreur lors de la récupération de l'horaire de travail";
        console.error(this.error, error);
      }
    },

    // Création d'un nouvel horaire de travail
    async createWorkingTime(workingTimeData, userID) {
      try {
        const response = await axios.post(`${BASE_API}/${userID}`, { workingTime: workingTimeData }, { headers: this.getHeaders() });
        this.workingTimeList.push(response.data);
      } catch (error) {
        this.error = "Erreur lors de la création de l'horaire de travail";
        console.error(this.error, error);
      }
    },

    // Mise à jour d'un horaire de travail
    async updateWorkingTime(userID, workingTimeId, workingTimeData) {
      try {
        const response = await axios.put(`${BASE_API}/${workingTimeId}`, { workingTime: workingTimeData }, { headers: this.getHeaders() });
        const index = this.workingTimeList.findIndex(workingTime => workingTime.id === workingTimeId);
        if (index !== -1) {
          this.workingTimeList[index] = response.data;
        }
      } catch (error) {
        this.error = "Erreur lors de la mise à jour de l'horaire de travail";
        console.error(this.error, error);
      }
    },

    // Suppression d'un horaire de travail
    async deleteWorkingTime(userID, workingTimeId) {
      try {
        await axios.delete(`/${BASE_API}/${workingTimeId}`, { headers: this.getHeaders() });
        this.workingTimeList = this.workingTimeList.filter(workingTime => workingTime.id !== workingTimeId);
      } catch (error) {
        this.error = "Erreur lors de la suppression de l'horaire de travail";
        console.error(this.error, error);
      }
    },
  },
});
