<template>
    <v-container>
      <v-card class="pa-5">
        <v-card-title>User Management</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="currentUser">
            <h2>Current User: {{ currentUser.username }}</h2>
            <v-text-field v-model="username" label="Name"></v-text-field>
            <v-text-field v-model="email" label="Email"></v-text-field>
            <v-btn color="primary" @click="updateUserData">Update User</v-btn>
            <v-btn color="error" @click="deleteUserData">Delete User</v-btn>
          </div>
          <div v-else>
            <p>No user is currently selected.</p>
            <v-text-field v-model="name" label="Name"></v-text-field>
            <v-text-field v-model="email" label="Email"></v-text-field>
            <v-btn color="info" @click="fetchCurrentUserData">Get User</v-btn>
            <v-btn color="success" @click="createNewUser">Create User</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </template> 
  
  <script>
  import { useUserStore } from '@/stores/user';
  import { onMounted , ref } from 'vue';
  
  export default {
    name: 'UserComponent',

    setup() {
      const userStore = useUserStore();
      const { currentUser, fetchCurrentUser, createUser, updateUser, deleteUser } = userStore;
      const username = ref("");
      const email = ref("");
      const password = ref("");

      onMounted(() => {
        fetchCurrentUser(); 
      });
  
      const fetchCurrentUserData = () => {
        fetchCurrentUser(); 
      };
  
      const createNewUser = () => {
        const newUser = {
          id: "",
          username: username.value,
          email: email.value,
          password : password.value,
          role_id: "87ffe789-6f7a-467b-a1be-75d8577b2bb8"
        };
        createUser(newUser);
      };
  
      const updateUserData = () => {
        const updatedUser = {          
          id: currentUser.id,
          username: username.value,
          email: email.value,
          password : password.value,
          role_id: currentUser.role.id
         };
        updateUser(currentUser.id, updatedUser);
      };
  
      const deleteUserData = () => {
        deleteUser(currentUser.id);
      };
  
      return {
        currentUser,
        fetchCurrentUserData,
        createNewUser,
        updateUserData,
        deleteUserData, 
      };
    },
  };
  </script>
  
  