<template>
  <v-container class="d-flex align-center justify-center">
    <v-card class="pa-5" max-width="100%" outlined>
      <v-card-title class="justify-center">Gestion des heures de travail</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- Si une période de travail est en cours -->
        <div v-if="clockIn" class="text-center">
          <h2>Heure actuelle : {{ startDateTime }} - {{ endDateTime || 'En cours' }}</h2>
          <h3>Durée totale : {{ calculateTotalWorkedTime() }}</h3> 

          <div class="date-time-picker">
            <v-time-picker
              color="green-lighten-1"
              v-model="startDateTime"
              format="24hr"
              label="Heure de début"
            ></v-time-picker>

            <v-time-picker
              color="pink"
              v-model="endDateTime"
              header-color="primary"
              format="24hr"
              label="Heure de fin"
              v-if="showPickers"
            ></v-time-picker>
          </div>

          <v-btn class="mt-4" color="primary" @click="endClocking">Terminer la période de travail</v-btn>
          <v-btn class="mt-2" color="error" @click="deleteCurrentClocking">Supprimer l'enregistrement</v-btn>
        </div>

        <div v-else class="text-center">
          <p>Aucune période de travail en cours.</p>
          <v-btn class="mt-4" color="success" @click="startNewClocking">Commencer une nouvelle période de travail</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useClockingStore } from '@/stores/clocking';
import { VTimePicker } from 'vuetify/labs/VTimePicker';

export default {
  name: 'ClockManager',
  components: { VTimePicker },

  setup() {
    const clockingStore = useClockingStore();
    const route = useRoute();
    const userID = "9012ba97-3f6a-4f73-bc2e-ebe09c3a4c3d"; 
    const clockIn = ref(false); // Vrai si une période de travail est en cours dans le body JSON 'true'
    const startDateTime = ref(null);
    const endDateTime = ref(null);
    const showPickers = ref(false);

    onMounted(async () => {
      const clockingID = route.params.clockingid;
      if (clockingID) {
        await clockingStore.fetchCurrentClocking(userID, clockingID);
        if (clockingStore.currentClocking) {
          clockIn.value = true;
          startDateTime.value = new Date(clockingStore.currentClocking.time); 
        }
      }
    });


    const startNewClocking = async () => {
      const newClocking = {
        time: new Date(),
        status: true,
        user_id: userID,
      };
      await clockingStore.createClocking(userID, newClocking);
      clockIn.value = true;
      startDateTime.value = newClocking.time;
      showPickers.value = true;
    };

    const endClocking = async () => {
      if (!clockingStore.currentClocking) {
        console.error('Aucune période de travail en cours à terminer.');
        return;
      }

      const updatedClocking = {
        endDateTime: new Date(),
      };
      await clockingStore.updateClocking(clockingStore.currentClocking.id, updatedClocking);
      clockIn.value = false;
      showPickers.value = false;
      endDateTime.value = updatedClocking.endDateTime;
    };

    // Méthode pour calculer la durée totale de travail mais ça marche pas
    const calculateTotalWorkedTime = () => {
      if (!startDateTime.value || !endDateTime.value) {
        return '0h 0m'; // Si une des deux dates est manquante
      }


      const start = new Date(startDateTime.value);
      const end = new Date(endDateTime.value);

      const diffMs = end - start; 

      // if (isNaN(diffMs)) {
      //   return '0h 0m'; // Éviter les résultats NaN
      // }

      const diffHrs = Math.floor(diffMs / (1000 * 60 * 60)); // Conversion en heures
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Conversion en minutes

      return `${diffHrs}h ${diffMins}m`;
    };

    const deleteCurrentClocking = async () => {
      if (!clockingStore.currentClocking) {
        console.error('Aucune période de travail à supprimer.');
        return;
      }

      await clockingStore.deleteClocking(clockingStore.currentClocking.id);
      clockIn.value = false;
      startDateTime.value = null;
      endDateTime.value = null;
    };

    return {
      clockIn,
      startDateTime,
      endDateTime,
      showPickers,
      startNewClocking,
      endClocking,
      deleteCurrentClocking,
      calculateTotalWorkedTime, 
    };
  },
};
</script>

<style scoped>
.v-container {
  height: 100vh;
  width: 100%;
  padding: 40px;
}

.v-card {
  width: 100%;
  max-width: 600px;
}

.text-center {
  text-align: center;
}

.date-time-picker {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-2 {
  margin-top: 8px;
}
</style>
