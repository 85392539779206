<template>
  <div class="d-flex justify-center">
      <v-date-input
        v-model="dateForm"
        label="Select range"
        max-width="368"
        multiple="range"
      ></v-date-input>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  <div class="calendar">
    <v-sheet class="d-flex" height="54" tile>
      <v-select
        v-model="type"
        :items="types"
        class="ma-2"
        label="View Mode"
        variant="outlined"
        dense
        hide-details
      ></v-select>
      <v-select
        v-model="weekday"
        :items="weekdays"
        class="ma-2"
        label="Weekdays"
        variant="outlined"
        dense
        hide-details
      ></v-select>
    </v-sheet>

    <v-sheet>
      <v-calendar
        ref="calendar"
        v-model="value"
        :events="events"
        :view-mode="type"
        :weekdays="weekday"
      ></v-calendar>
    </v-sheet>

    <!-- Sélecteur de plage de dates -->
    <v-date-picker
      v-model="dateRange"
      type="range"
      is-required
      label="Sélectionnez la plage de dates"
      :rules="[rules.required]"
    ></v-date-picker>

    


    <!-- Actions sur les horaires de travail -->
    <v-btn @click="createNewWorkingTime" :disabled="workingTimeStore.loading">Créer un horaire</v-btn>
    <v-btn 
      @click="updateExistingWorkingTime(workingTimeId)"
      :disabled="!workingTimeId || workingTimeStore.loading"
    >Mettre à jour</v-btn>
    <v-btn 
      @click="deleteExistingWorkingTime(workingTimeId)" 
      :disabled="!workingTimeId"
    >Supprimer</v-btn>

    <!-- Affichage de la durée entre les dates -->
    <p>Durée (en heures) : {{ getHoursSelectorInfos() }}</p>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useWorkingTimeStore } from '@/stores/workingTime';
import { VCalendar } from 'vuetify/labs/VCalendar';
import { VDateInput } from 'vuetify/labs/VDateInput';

export default {
  name: "CalendarComponent",
  components: {
    VCalendar,
    VDatePicker: () => import('vuetify/lib/components/VDatePicker/VDatePicker'),
    VDateInput,
  },
  setup() {
    const workingTimeStore = useWorkingTimeStore();
    const events = ref([]);
    const userID = '9012ba97-3f6a-4f73-bc2e-ebe09c3a4c3d';
    const dateRange = ref([new Date(), new Date()]); // Plage de dates sélectionnée

    // Fonction pour créer un nouvel horaire de travail
    const createWorkingTime = async (workingTimeData) => {
      try {
        await workingTimeStore.createWorkingTime(workingTimeData, userID);
      } catch (error) {
        console.error("Erreur lors de la création de l'horaire :", error);
      }
    };

    // Fonction pour mettre à jour un horaire de travail
    const updateWorkingTime = async (workingTimeId, workingTimeData) => {
      try {
        await workingTimeStore.updateWorkingTime(userID, workingTimeId, workingTimeData);
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'horaire :", error);
      }
    };

    // Fonction pour supprimer un horaire de travail
    const deleteWorkingTime = async (workingTimeId) => {
      try {
        await workingTimeStore.deleteWorkingTime(userID, workingTimeId);
      } catch (error) {
        console.error("Erreur lors de la suppression de l'horaire :", error);
      }
    };

    // Observation de la liste des horaires de travail pour mettre à jour les événements
    watch(
      () => workingTimeStore.workingTimeList,
      (newVal) => {
        try {
          events.value = newVal.map((workingTime) => ({
            title: "Travail",
            start: new Date(workingTime.entrance_datetime),
            end: new Date(workingTime.exit_datetime),
            color: "blue",
          }));
        } catch (error) {
          console.error("Erreur lors du mapping des horaires :", error);
        }
      },
      { immediate: true }
    );

    return {
      createWorkingTime,
      updateWorkingTime,
      deleteWorkingTime,
      workingTimeStore,
      events,
      dateRange,
    };
  },
  data: () => ({
    type: 'month',
    types: ['month', 'week', 'day'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    dateForm: [],
    weekdays: [
      { title: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { title: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { title: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { title: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    value: [new Date()],
    workingTimeId: '',
    rules: {
      required: (v) => !!v || 'Ce champ est requis',
    }
  }),
  methods: {
    getHoursSelectorInfos() {
      if (this.dateRange[0] && this.dateRange[1]) {
        const durationMs = this.dateRange[1].getTime() - this.dateRange[0].getTime();
        return (durationMs / (1000 * 60 * 60)).toFixed(2);
      }
      return 0;
    },
    async createNewWorkingTime() {
      const start = this.dateForm[0];
      const end = this.dateForm[1];
    
      if (start.getTime() >= end.getTime()) {
        console.error("La date de début doit être avant la date de fin.");
        return;
      }
      const workingTimeData = {
        entrance_datetime: start,
        exit_datetime: end,
      };
      await this.createWorkingTime(workingTimeData);
    },
    async updateExistingWorkingTime(workingTimeId) {
      const [start, end] = this.dateRange;
      if (start && end) {
        const workingTimeData = {
          entrance_datetime: start,
          exit_datetime: end,
        };
        await this.updateWorkingTime(workingTimeId, workingTimeData);
      } else {
        console.error("Les dates doivent être sélectionnées.");
      }
    },
    async deleteExistingWorkingTime(workingTimeId) {
      if (workingTimeId) {
        await this.deleteWorkingTime(workingTimeId);
      } else {
        console.error("L'ID de l'horaire doit être fourni.");
      }
    },
  },
};
</script>
