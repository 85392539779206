
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import UserComponent from '@/components/UserComponent.vue';
import ManagerPage from '@/pages/ManagerPage.vue';
import ClockManagerPage from '@/pages/ClockManagerPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import MainPage from '@/pages/MainPage.vue';
import { isAuthenticated } from '@/auth/auth';
import MonitoringPage from '@/pages/MonitoringPage.vue';
import WorkingHistoryPage from '@/pages/WorkingHistoryPage.vue';

const routes = [
    {
        path: '/',
        redirect: '/login'
    }, 
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage,
        beforeEnter: (to, from, next) => {
            if (!isAuthenticated()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [
            {
                path: '',
                name: 'MainPage',
                component: MainPage,
            },
            {
                path: 'viewchart',
                name: 'ViewMyClockin',
                component: MonitoringPage,
            },
             {
                path: 'viewmanager',
                name: 'ManagerView',
                component: ManagerPage,
            },
            {   path: 'clocking',
                name: 'ClockManagerPage',
                component: ClockManagerPage,
            },
            {   
                path: 'working-history',
                name: 'WorkingHistoryPage',
                component: WorkingHistoryPage
            },
            {
                path: 'user',
                name: 'UserProfile',
                component: UserComponent
            }
        ]
    }
];




const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
