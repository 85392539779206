import { createApp } from 'vue'
import App from './App.vue'

// Import Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, fa } from 'vuetify/iconsets/fa'
import '@fortawesome/fontawesome-free/css/all.css';

const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        fa,
      },
    },
  })

import axios from 'axios'
import VueAxios from 'vue-axios'

// Chart
import { Chart, DoughnutController, ArcElement, Tooltip } from 'chart.js'
import VueApexCharts from 'vue3-apexcharts'

Chart.register(DoughnutController, ArcElement, Tooltip)

// Pinia Store 
import { createPinia } from 'pinia'
const pinia = createPinia()

// Router
import router from '@/router/index'

createApp(App)
  .use(vuetify)
  .use(router)
  .use(pinia)
  .use(VueApexCharts)  // Correction ici
  .use(VueAxios, axios)
  .mount('#app')
