<template>
    <div style="padding: 10px;">
        <h1 style="color:#504099">Historique des heures travailées</h1>
        <CalendarComponent/>
    </div>
</template>
<script>
import CalendarComponent from '@/components/CalendarComponent.vue';

export default ({
    name: "WorkingHistoryPage",
    components : {CalendarComponent}
})
</script>