<template>
  <ClockManager/>
</template>

<script>

import ClockManager from '@/components/ClockManager.vue';

export default {
  name: "ClockManagerPage",
  components: { ClockManager },
}

</script>