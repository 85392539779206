<template>
    <div style="padding: 10px;">
        <h1 style="color:#504099">Temps de travail</h1>
        <ChartClockin/>
    </div>
</template>
<script>
import ChartClockin from '@/components/ChartClockinPage.vue';

export default ({
    name: "MonitoringPage",
    components : {ChartClockin}
})
</script>