<template>
  <div class="listEmployee">
    <h1>Liste des employées</h1>

    <v-card class="add-user-form" max-width="60%" >
      <v-card-title>Ajouter un nouvel employé</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="newUser.username"
            :rules="[rules.required]"
            label="Nom d'utilisateur"
            required
          ></v-text-field>
          <v-text-field
            v-model="newUser.email"
            :rules="[rules.required, rules.email]"
            label="Email"
            required
          ></v-text-field>
          <v-select
            v-model="newUser.role"
            :items="roles"
            :rules="[rules.required]"
            label="Rôle"
            required
          ></v-select>
          <v-text-field
            v-model="newUser.password"
            :rules="[rules.required]"
            label="Mot de passe"
            type="password"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="addUser">Ajouter</v-btn>
      </v-card-actions>
    </v-card>

    <div v-if="loading" class="loader-container">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-row dense>
      <v-col v-for="(employee, i) in sortedEmployees" :key="i" cols="12" md="4">
        <v-card  class="employee-card"  v-if="employee.role.name === 'Directeur général'">
          <template v-slot:actions>
            <v-btn color="secondary" @click="editUser(employee)">Modifier</v-btn>
            <v-btn color="error" @click="confirmDelete(employee.id)">Supprimer</v-btn>
            <v-select label="Changement de Rôle" v-model="employee.role.name" :items="roles" @change="updateUserRole(employee)"></v-select>
          </template>
          <div>
            <h2>{{ employee.username }}</h2>
            <h3>Directeur Général</h3>
            <p>Email: {{ employee.email }}</p>
          </div>
        </v-card>
        <v-card  class="employee-card" v-if="employee.role.name === 'Manageur'">
          <template v-slot:actions>
            <v-btn color="secondary" @click="editUser(employee)">Modifier</v-btn>
            <v-btn color="error" @click="confirmDelete(employee.id)">Supprimer</v-btn>
            <v-select label="Changement de Rôle" v-model="employee.role.name" :items="roles" @change="updateUserRole(employee)"></v-select>
          </template>
          <div>
            <h2>{{ employee.username }}</h2>
            <h3>Manageur</h3>
            <p>Email: {{ employee.email }}</p>
          </div>
        </v-card>
        <v-card  class="employee-card"  v-if="employee.role.name === 'Employé'">
          <template v-slot:actions>
            <v-btn color="secondary" @click="editUser(employee)">Modifier</v-btn>
            <v-btn color="error" @click="confirmDelete(employee.id)">Supprimer</v-btn>
            <v-select label="Changement de Rôle" v-model="employee.role.name" :items="roles" @change="updateUserRole(employee)"></v-select>
          </template>
          <div>
            <h2>{{ employee.username }}</h2>
            <h3>Employée</h3>
            <p>Email: {{ employee.email }}</p>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Delete Pop-up -->
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirmation de suppression</v-card-title>
        <v-card-text>
          Êtes-vous sûr de vouloir supprimer cet employé ? Cette action est irréversible.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">Annuler</v-btn>
          <v-btn color="error" text @click="deleteUserConfirmed">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ListEmployee",
  data() {
    return {
      employees: [],
      loading: true,
      valid: false,
      deleteDialog: false,
      deleteUserId: null,
      newUser: {
        username: null,
        email: null,
        role: null,
        password: null,
      },
      roles: ["Directeur Général", "Manageur", "Employé"],
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
        email: (value) => {
          const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
          return pattern.test(value) || "Adresse email invalide.";
        },
      },
    };
  },
  computed: {
    sortedEmployees() {
      return this.employees
        .map(employee => {
          if (employee.role.name === 'Directeur gÃ©nÃ©ral') {
            employee.role.name = 'Directeur général';
          } else if (employee.role.name === 'Manageur') {
            employee.role.name = 'Manageur';
          } else if (employee.role.name === 'EmployÃ©') {
            employee.role.name = 'Employé';
          }
          return employee;
        })
        .sort((a, b) => {
          const roleOrder = { "Directeur général": 1, Manageur: 2, Employé: 3 };
          return roleOrder[a.role.name] - roleOrder[b.role.name];
        });
    },
  },
  mounted() {
    this.fetchEmployees();
  },
  methods: {
    async fetchEmployees() {
      try {
        const response = await axios.get("http://localhost:4000/api/users/all");
        this.employees = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching employees:", error);
        // this.loading = false;
      }
    },
    async addUser() {
      if (this.$refs.form.validate()) {
        try {
          const newUserPayload = {
            user: {
              id: null,
              username: this.newUser.username,
              password: this.newUser.password,
              email: this.newUser.email,
              role_id: this.getRoleId(this.newUser.role),
            },
          };
          await axios.post("http://localhost:4000/api/users", newUserPayload);
          this.fetchEmployees();
          this.resetForm();
        } catch (error) {
          console.error("Error adding user:", error);
        }
      }
    },
    async editUser(employee) {
      try {
        const updatedUserPayload = {
          user: {
            id: employee.id,
            username: employee.username,
            password: employee.password, 
            email: employee.email,
            role_id: this.getRoleId(employee.role.name),
          },
        };
        await axios.put(`http://localhost:4000/api/users/${employee.id}`, updatedUserPayload);
        this.fetchEmployees(); 
      } catch (error) {
        console.error("Error updating user:", error);
      }
    },
    async updateUserRole(employee) {
      try {
        const updatedUserPayload = {
          user: {
            id: employee.id,
            username: employee.username,
            password: employee.password,
            email: employee.email,
            role_id: this.getRoleId(employee.role.name),
          },
        };
        await axios.put(`http://localhost:4000/api/users/${employee.id}`, updatedUserPayload);
        this.fetchEmployees(); 
      } catch (error) {
        console.error("Error updating user role:", error);
      }
    },
    confirmDelete(userId) {
      this.deleteUserId = userId;
      this.deleteDialog = true;
    },
    async deleteUserConfirmed() {
      try {
        await axios.delete(`http://localhost:4000/api/users/${this.deleteUserId}`);
        this.fetchEmployees(); 
        this.deleteDialog = false;
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    getRoleId(roleName) {
      switch (roleName) {
        case "Directeur Général":
          return "e607a88e-e59d-43ea-af6a-503a6f596fb2";
        case "Manageur":
          return "ce66bab0-0a99-4ad3-ba89-0b08b8d1fb71";
        case "Employé":
          return "87ffe789-6f7a-467b-a1be-75d8577b2bb8";
        default:
          return null;
      }
    },
    resetForm() {
      this.newUser = {
        username: null,
        email: null,
        role: null,
        password: null,
      };
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

h1 {
  margin-left: 1%;
  color: #504099;
  font-weight: 300;
}

.listEmployee {
  padding: 16px;
}

.text-caption {
  margin-top: 8px;
}

.employee-card {
  color: #504099;
  margin-bottom: 10px;}

.add-user-form {
  margin-bottom: 24px;
}
</style>
