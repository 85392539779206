<template>
  <v-navigation-drawer
    theme="dark"
    permanent
    style="background: linear-gradient(153deg, rgba(49,56,102,1) 0%, rgba(151,78,195,1) 100%);"
  >
    <v-list>
      <!-- Ajouter un élément de chargement pendant la récupération des données -->
      <v-list-item v-if="loading" title="Chargement..." prepend-avatar="https://via.placeholder.com/150"></v-list-item>
      <v-list-item v-else
        :prepend-avatar="require('@/assets/img/avatar.png')"
        :subtitle="currentUser.email"
        :title="currentUser.username"
      ></v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list color="transparent">
      <v-list-item title="Accueil" prepend-icon="fas fa-home" @click="navigateTo('MainPage')"></v-list-item>
      <v-list-item title="Monitoring" prepend-icon="fas fa-chart-line" @click="navigateTo('ViewMyClockin')"></v-list-item>
      <v-list-item title="Historique des heures" prepend-icon="fas fa-calendar-days" @click="navigateTo('WorkingHistoryPage')"></v-list-item>
      <v-list-item title="Pointage d'heure" prepend-icon="fas fa-clock" @click="navigateTo('ClockManagerPage')"></v-list-item>
      <v-list-item v-if="!loading && currentUser.role.id === 'e607a88e-e59d-43ea-af6a-503a6f596fb2'" title="Organisations" prepend-icon="fas fa-building" @click="navigateTo('ManagerView')"></v-list-item>
      <v-list-item v-if="!loading && currentUser.role.id !== 'ce66bab0-0a99-4ad3-ba89-0b08b8d1fb71'" title="Teams" prepend-icon="fas fa-people-group" @click="navigateTo('ManagerView')"></v-list-item>
      <v-list-item title="Mon profil" prepend-icon="fas fa-user" @click="navigateTo('UserProfile')"></v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="logout">
          Déconnexion
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';

export default defineComponent({
  name: 'NavbarComponent',

  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const { fetchCurrentUser, currentUser } = userStore;

    const loading = ref(true);

    const navigateTo = (pageName) => {
      router.push({name: pageName});
    };

    const logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('userID');
      router.push({ name: 'LoginPage' });
    };

    onMounted(async () => {
      try {
        await fetchCurrentUser();
      } catch (error) {
        console.error("Erreur lors de la récupération des données utilisateur", error);
      } finally {
        loading.value = false;
      }
    });

    return {
      navigateTo,
      currentUser,
      logout,
      loading,
    };
  }
});
</script>