<template>
    <NavbarComponent v-if="isOpen" />
    <div class="container">
      <v-btn @click="toggleNavbar" density="default" icon="fas fa-bars" size="small" class="menu-btn"></v-btn>
      <router-view />
    </div>
 </template>
  
<style>
    .container {
      padding: 2rem;
      width: 100%;
    }
    .menu-btn {
      color: #974ec3;
    }
</style>
  
<script>
  import NavbarComponent from '@/components/NavbarComponent.vue';
  import { ref } from 'vue';
  
  export default {
    name: 'HomePage',
    components: { NavbarComponent },
  
    setup() {
      const isOpen = ref(true);
  
      const toggleNavbar = () => {
        isOpen.value = !isOpen.value;
      };
  
      return {
        isOpen,
        toggleNavbar,
      };
    },
  };
</script>