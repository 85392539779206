import { defineStore } from 'pinia';
import axios from 'axios';
import { env } from '../../env';

const BASE_API = `${env.apiURL}/users`;

export const useUserStore = defineStore('user', {
  state: () => ({
    userList: [],
    currentUser: {
      id: "",
      username: "",
      password: "",
      email: "",
      role: {
        id: "",
        name: ""
      }
    },
  }),
  
  actions: {
    getHeaders() {
      const token = localStorage.getItem('token');
      return {
        Authorization: `Bearer ${token}`,
      };
    },

    async fetchUserList() {
      try {
        const response = await axios.get(BASE_API, { headers: this.getHeaders() }); 
        this.userList = response.data;
      } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs", error);
      }
    },
    
    async fetchCurrentUser() {
      const userID = localStorage.getItem("userID");
      if (!userID) {
        console.error("Aucun ID d'utilisateur trouvé");
        return;
      }
      try {
        const response = await axios.get(`${BASE_API}/${userID}`, { headers: this.getHeaders() });
        this.currentUser = response.data;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'utilisateur", error);
      }
    },

    async connectUser(email, password) {
      try {
        const response = await axios.post(`${env.apiURL}/auth/login`, {
          email: email,
          password: password
        });

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userID', response.data.userID);
      } catch (error) {
        throw new Error(error.response.data?.message);
      }
    },

    async createUser(userData) {
      try {
        const response = await axios.post(BASE_API, { user: userData }, { headers: this.getHeaders() });
        this.userList.push(response.data); 
      } catch (error) {
        console.error("Erreur lors de la création de l'utilisateur", error);
      }
    },

    async updateUser(userId, userData) {
      try {
        const response = await axios.put(`${BASE_API}/${userId}`, { user: userData }, { headers: this.getHeaders() });
        const index = this.userList.findIndex(user => user.id === userId);
        if (index !== -1) {
          this.userList[index] = response.data; 
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'utilisateur", error);
      }
    },

    async deleteUser(userId) {
      try {
        await axios.delete(`${BASE_API}/${userId}`, { headers: this.getHeaders() });
        this.userList = this.userList.filter(user => user.id !== userId);
      } catch (error) {
        console.error("Erreur lors de la suppression de l'utilisateur", error);
      }
    },
  },
});
