import { defineStore } from 'pinia';
import axios from 'axios';
import { env } from '../../env';

const BASE_API = `${env.apiURL}/clocks`

export const useClockingStore = defineStore('clocking', {
  state: () => ({
    clockingList: []
  }),

  actions: {
    getHeaders() {
      const token = localStorage.getItem('token');
      return {
        Authorization: `Bearer ${token}`,
      };
    },

    async fetchClockingList(userID) {
      try {
        const response = await axios.get(`${BASE_API}/${userID}`, { headers: this.getHeaders() });
        this.clockingList = response.data;
      } catch (error) {
        console.error("Error to get list of clocking", error);
      }
    },

    /*Example of ClockingData
      {
        id: string,
        time: DateTime,
        status: boolean (False if out, true for in),
        user_id: string
      }
    */
    async createClocking(userID, clockingData) {
      try {
        const response = await axios.post(`${BASE_API}/${userID}`, { clocking: clockingData }, { headers: this.getHeaders() });
        this.clockingList.push(response.data);
      } catch (error) {
        console.error("Error to add a clock", error);
      }
    }
  },
});
