<template>
  <v-container fluid class="login-container">
    <v-row align="center" justify="center" style="min-height: 80vh;">
      <v-col cols="12" sm="8" md="4">
        <v-card class="mx-auto pa-12 pb-8" elevation="8" rounded="lg">
          <h1 class="title">Login</h1>
          <div class="text-subtitle-1 text-medium-emphasis">Account</div>

          <v-text-field
            v-model="emailInput"
            density="compact"
            placeholder="Email address"
            prepend-inner-icon="fas fa-envelope"
            variant="outlined"
          ></v-text-field>

          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Password
            <a class="text-caption text-decoration-none text-blue" href="#" rel="noopener noreferrer" target="_blank">
              Forgot login password?
            </a>
          </div>

          <v-text-field
            v-model="passwordInput"
            :append-inner-icon="visible ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            placeholder="Enter your password"
            prepend-inner-icon="fas fa-lock"
            variant="outlined"
            @click:append-inner="visible = !visible"
          ></v-text-field>

          <v-card class="mb-12" color="surface-variant" variant="tonal" v-if="error">
            <v-card-text class="text-medium-emphasis text-caption text-error">
              {{error}}
            </v-card-text>
          </v-card>

          <v-btn class="mb-8 submit-btn" size="large" variant="tonal" block @click="login">
            Log In
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
/* Ajout du fond d'écran violet */
.login-container {
  background-color: #504099;
  height: 100vh;
}

.title {
  color: #504099;
  margin-bottom: 1rem;
}

.text-subtitle-1 {
  color: #504099;
}

.text-error {
  color: red;
}

.submit-btn {
  background-color: #504099; 
  color: white;
}
</style>

<script setup>
  import { useUserStore } from '@/stores/user';
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';

  const useStore = useUserStore();
  const { connectUser } = useStore;

  const emailInput = ref("");
  const passwordInput = ref("");
  const error = ref("");
  const visible = ref(false);

  const router = useRouter();

  const login = async () => {
    const email = emailInput.value;
    const password = passwordInput.value;
    
    if(email !== '' && password !== '') {
      try {
        await connectUser(email, password);
        router.push('/home');
      } catch (e) {
        error.value = e.message;
      }
    } else {
      error.value = "Fill the form";
    }
  };

</script>
