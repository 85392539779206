<template>
  <div id="chart">
    <!-- Accès à chartOptions et series dans le template -->
    <apexchart type="area" height="750" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'; // Import ref pour les réactifs
import VueApexCharts from 'vue3-apexcharts';
import { useWorkingTimeStore } from '@/stores/workingTime';
import { onMounted } from 'vue';

export default defineComponent({
  name: "ChartClockin",
  components: {
    apexchart: VueApexCharts,
  },

  setup() {
    const workingStore = useWorkingTimeStore();
    const { workingTimeList, fetchWorkingTimeList } = workingStore;

    // Déclarer chartOptions et series comme des refs réactifs
    const chartOptions = ref({
      chart: {
        height: 350,
        type: 'area',
      },
      colors: ['#974EC3', '#FE7BE5'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],  
        labels: {
          rotate: -45,
          show: true,
        },
      },
      yaxis: {
        title: {
          text: 'Heures travaillées'
        },
        min: 0,
        max: 15,
      },
      tooltip: {
        x: {
          format: 'HH:mm',
        },
      },
    });

    const series = ref([
      {
        name: 'Mon temps',
        data: [], // Initial empty data
      },
    ]);

    const fetchDataAndUpdateChart = async () => {
      const userID = "dc605979-9c76-48f4-8876-bbdce96b59d5"; // localStorage.getItem("userID")
      const startTime = new Date("2024-10-10T07:00:00");
      const endTime = new Date("2024-10-15T07:00:00");

      try {
        // Fetch the working time list from the store
        await fetchWorkingTimeList(userID, startTime, endTime);

        // Process the data to update the chart
        const workedHours = [];
        const categories = [];

        workingTimeList.forEach(entry => {
          const entranceTime = new Date(entry.entrance_datetime);
          const exitTime = new Date(entry.exit_datetime);
          let hoursWorked = (exitTime - entranceTime) / (1000 * 60 * 60); // Convert milliseconds to hours
          hoursWorked = Math.round(hoursWorked * 50) / 50; // Round to the nearest 0.5 hours

          workedHours.push(hoursWorked);
          categories.push(entranceTime.toLocaleDateString("fr-FR")); // Format as a date string
        });

        // Update the chart options and series data
        chartOptions.value.xaxis.categories = categories;
        series.value[0].data = workedHours;
      } catch (error) {
        console.log(error)
      }
    };

    onMounted(() => {
      fetchDataAndUpdateChart();
    });

    return {
      chartOptions, // Retourne chartOptions et series pour les utiliser dans le template
      series,
    };
  },
});
</script>
